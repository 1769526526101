import { FC, lazy, memo } from "react"
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom"

import { DefaultLayout } from "../components/layout"
import { Loader } from "../components/layout/Loader"

const Home = lazy<FC>(() => import("./home"))
const Chat = lazy<FC>(() => import("./chat"))
const PromptLibrary = lazy<FC>(() => import("./promptLibrary"))
const PromptLibrarySinglePage = lazy<FC>(() => import("./promptLibrarySinglePage"))
const LoginPage = lazy<FC>(() => import("./login"))
const SignupPage = lazy<FC>(() => import("./signup"))
const NewPersona = lazy<FC>(() => import("./new-persona"))
const Profile = lazy<FC>(() => import("./profile"))
const Notification = lazy<FC>(() => import("./notification"))
const History = lazy<FC>(() => import("./history"))
const Subscription = lazy<FC>(() => import("./subscription"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "/preboarding",
        index: true,
      },
      {
        Component: NewPersona,
        path: "new-persona",
        index: true,
      },
      {
        Component: Profile,
        path: "profile",
        index: true,
      },
      {
        Component: Notification,
        path: "notifications",
        index: true,
      },
      {
        Component: History,
        path: "history",
        index: true,
      },
      {
        Component: Chat,
        path: "/chat/",
        children: [{
          Component: PromptLibrary,
          path: "library",
        }, {
          Component: Chat,
          path: ":route",
          index: true,
        }]
      },
      {
        Component: PromptLibrarySinglePage,
        path: 'library/:key',
      },
      {
        Component: LoginPage,
        path: "/signin",
        index: true,
      },
      {
        Component: SignupPage,
        path: "/signup",
        index: true,
      },
      {
        Component: Subscription,
        path: "/subscription",
        index: true,
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

const router = createBrowserRouter(routes)

const PageProvider = memo(() => <RouterProvider router={router} />)

export default PageProvider
