import { useGetNotificationsQuery, useGetUserNotificationCustomQuery } from "../graphql"
import { useContext, useEffect, useState } from "react"
import { Context } from "../components/app"

export const useGetNotifications = () => {

  const { user } = useContext(Context)

  const [ notifications, setNotifications ] = useState<GetUserNotifications[]>([])
  const [ total, setTotal ] = useState(0)
  const { data, loading } = useGetUserNotificationCustomQuery({variables: { input: {userId: user?.id || ''}}})

  useEffect(() => {
    if (data && !notifications?.length) {
      setNotifications(data?.getUserNotifications as GetUserNotifications[] || [])
      setTotal(data?.getUserNotifications?.filter(item => !!item?.isNew)?.length || 0)
    }
  }, [data])

  return {data: notifications, loading, total}
}