import { StrictMode } from "react"
import { createRoot } from "react-dom/client"

import App from "./components/app"

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const rootElement = document.querySelector("#root") as HTMLElement

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
)

