import { useGetGlobalSettingQuery, useGetUsersMenuQuery } from "../graphql"
import { useContext } from "react"
import { Context } from "../components/app"
import { useLocation } from "react-router"

export const useDefaultRoute = () => {

  const location = useLocation().pathname.split('/chat/').join('')

  const { data: globalData } = useGetGlobalSettingQuery()
  const { user } = useContext(Context)
  const { data } = useGetUsersMenuQuery({variables:{filter:{users_permissions_user:{id:{eq: user?.id}}}}})
  const navigations = data?.usersMenus?.data
  const firstRouteValue = navigations?.[0]?.attributes?.menu_groups?.data?.[0]?.attributes?.menu_navigations?.data?.[0]?.attributes?.prompt_type?.data?.attributes?.key || ''
  const defaultRouteValue = globalData?.globalSetting?.data?.attributes?.defaultRoute

  const createRoute = (text: string | null | undefined): string => {
    return text ? text.toLowerCase().split(" ").join("-") : ""
  }

  return (location === 'general' || location === 'library') ? createRoute(location) : navigations?.[0]?.attributes?.menu_groups?.data
    ?.find(group => group?.attributes?.menu_navigations?.data?.some(route => route?.attributes?.prompt_type?.data?.attributes?.key === defaultRouteValue))
    ?.attributes?.menu_navigations?.data?.find(route => route?.attributes?.prompt_type?.data?.attributes?.key === defaultRouteValue)
    ? createRoute(defaultRouteValue)
    : createRoute(firstRouteValue)
}
