import { Space } from "antd"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { ReactComponent as LogoImage } from "./Logo.svg"

const Logo: FC<{disabled?: boolean}> = ({ disabled }) => {
  const navigate = useNavigate()
  return (
      <LogoImage onClick={() => !disabled && navigate("/chat")} />
  )
}

export { Logo }
