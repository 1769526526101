import { Button, Row } from "antd"
import { FC, Fragment, useContext } from "react"
import { useLocation } from "react-router"
import { Logo } from "../logo"
import './Header.less'
import { Login } from "../login"
import { Context } from "../app"
import {  ReactComponent as NotificationSVG } from "../../media/notification-bell.svg"
import { useNavigate } from "react-router-dom"
import { useGetNotifications } from "../../hooks/useGetNotifications"

const Header: FC = () => {
  const { pathname } = useLocation()
  const { user, setIsOpenSubscriptionModal, isBlockedNavigation } = useContext(Context)
  const isAuthPage = pathname === "/signup" || pathname === "/signin"
  const navigate = useNavigate()

  const {data: notificationData, total} = useGetNotifications()

  return (
    <Fragment>
      {isAuthPage
        ? <Row wrap={false} justify={"center"} className={"auth-header"}>

        </Row>
        : <Row wrap={false} justify={"space-between"} className={'header'}>
          <Logo disabled={isBlockedNavigation}/>
          <Row align={'middle'} className={'right-part'}>
            {!!user && <Fragment>
              {user?.subscription?.type === "free" &&
                <Button className={"upgrade"} type={'link'} onClick={() => navigate("/subscription")} disabled={user?.closed}>
                  <div>UPGRADE</div>
                </Button>}
              {!!total ?
                <Button type={"text"} className={"notifications"} disabled={isBlockedNavigation} onClick={() => navigate("/notifications")}>
                  <p>🏝️ {total} {total > 1 ? 'updates' : 'update'} since you’ve been gone</p>
                  <NotificationSVG />
                </Button> :
                <Button type={"text"} className={"notifications bell"} disabled={isBlockedNavigation} onClick={() => navigate("/notifications")}>
                  <NotificationSVG />
                </Button>}
            </Fragment>}
            <Login type={'redirect'}/>
          </Row>
        </Row>
      }
    </Fragment>
  )
}

export { Header }
