import { Button, Col, Modal, Row, Typography } from "antd"
import { FC, useContext } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm"

import "./EndOfPlanWindow.less"

import { Context } from "../app"
import { useModalContentsQuery } from "src/graphql"

const EndOfPlanWindow: FC = () => {
  const { isOpenEndOfPlanModal, setIsOpenEndOfPlanModal } = useContext(Context)
  const { data } = useModalContentsQuery()
  const modalData = data?.modalContents?.data.find(modal => modal.attributes?.name === "tokensEndOnPaidPlan")?.attributes
  
  return (
    <Modal
      title={modalData?.title}
      centered
      open={isOpenEndOfPlanModal}
      footer={[]}
      onCancel={() => setIsOpenEndOfPlanModal(false)}
    >
      <Row>
        {modalData?.text &&
            /*@ts-ignore*/
            <ReactMarkdown className={"text-wrapper"} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
            {modalData?.text}
          </ReactMarkdown>}
      </Row>
      <Row justify={"center"}>
        {modalData?.button?.map(button => <Button key={button?.id} href={button?.url} type={"primary"} className={"email-us-btn"}>{button?.title}</Button>)}
      </Row>
    </Modal>
  )
}

export default EndOfPlanWindow
