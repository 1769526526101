import { useContext } from "react"

import { Context, User } from "../components/app"
import { useUpdateSubscriptionMutation } from "../graphql"

type SubscriptionDetails = {
  type?: string | undefined,
  key?: string | undefined,
  startDate?: string | undefined,
  updatedDate?: string | undefined,
  finishDate?: string | undefined
}

export const clearSubscription = async (prompts: number | undefined, subscriptionData?: SubscriptionDetails ) => {
  const { user, setUser } = useContext(Context);

  const [updateSubscription] = useUpdateSubscriptionMutation()  
  
    try {
      const updatedSubscription = await updateSubscription({
        variables: {
          id: user?.id!,
          input: {
            subscription: {
              prompts,
              type: subscriptionData?.type ?? 'free',
              key: subscriptionData?.key ?? '',
              startDate: subscriptionData?.startDate ?? null,
              updatedDate: subscriptionData?.updatedDate ?? null,
              finishDate: subscriptionData?.finishDate ?? null,
            }
          }
        }
      })
      const subscription = updatedSubscription?.data?.updateUsersPermissionsUser?.data?.attributes?.subscription!
      setUser({
        username: user?.username!,
        id: user?.id!,
        email: user?.email!,
        profile: user?.profile!,
        closed: user?.closed!,
        subscription: {
          id: subscription?.ticketId!,
          type: subscription.type,
          startDate: subscription?.startDate!,
          prompts: subscription.prompts,
          key: subscription?.key!,
          updatedDate: subscription?.updatedDate!,
          finishDate: subscription?.finishDate!,
        }
      } as User)

      setUser({...user, subscription: {...user?.subscription, prompts: prompts ?? 0}} as User)
    } catch (e) {
      console.error(e)
    }
  }