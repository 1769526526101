import './SubscriptionCard.less'
import { Col, Row, Typography, Button } from "antd"
import React, { FC, useContext } from "react"
import { CheckCircleFilled, CheckOutlined } from "@ant-design/icons"
import { Context } from "../app"
import CustomButton from "../button"

type iProps = {
  timePlan: Maybe<ComponentDataEntry>,
  price: Maybe<number>,
  advantages: Maybe<Maybe<ComponentDataSet>[]>,
  onSubscribe: () => void,
  current?: boolean,
  isRecommended?: boolean,
  isYearly: boolean,
  color?: string,
  icon?: string,
  title: string,
  description?: string
}

const SubscriptionCard:FC<iProps> = ({price, onSubscribe , advantages, isRecommended, timePlan, current, isYearly, color, icon, title, description}) => {

  return (
    <Row className={`subscription-card`}>
      <div className={'icon'} style={{background: color}}>
        {icon}
      </div>
      <Row className={'head-card'} justify={'space-between'} align={'middle'}>
        <Typography.Title level={4} className={`title`}>{title}</Typography.Title>
        {/*<div className={'hint'}>*/}
        {/*  {(selected && !current) && <div className={'selected-icon'}><CheckCircleFilled /></div>}*/}
        {/*  {current && <p className={'current'}>*/}
        {/*    CURRENT*/}
        {/*  </p>}*/}
        {/*</div>*/}
      </Row>
      <Row>
          <p className={"subscription-description"}>{description}</p>
      </Row>
      <Row className={'price-container'} justify={"space-between"}>
        <Row>
          <p className={'price'}>
            {price === 0 ? <span className={'free'}>FREE</span> : `$${price}`}
          </p>
          {price !== 0 && <span className={'per-period'}>{isYearly ? "/mo" : "/mo"}</span>}
        </Row>
        {isRecommended && <p className={"recommended"}>RECOMMENDED</p>}
      </Row>
      <Row className={'advantages-list'}>
        {advantages?.map((item) => {
          return <p className={'advantage'} key={item?.id}>
            <CheckOutlined />&nbsp;
            {item?.value}
          </p>
        })}
      </Row>
      <Row className={'action-buttons'}>
        {
          current ?
            <CustomButton
              disabled
              btnText={"Current Plan"}
            /> :
            <CustomButton
              className={"subscribe-btn SS_ProductCheckout"}
              onClick={onSubscribe}
              btnText={"Get Started"}/>
        }
      </Row>
    </Row>
  )
}

export { SubscriptionCard }