import './SubscriptionWindow.less'
import { Button, Col, Row, Typography, Modal } from "antd"
import React, { FC, useContext, useEffect, useState } from "react"
import { SubscriptionCard } from "../subscriptionCard"
import { clearSubscription } from '../../helpers/clearSubscription'
import { useGetSeasonTicketsQuery } from "../../graphql"
import { Context } from "../app"
import { SS_ProductCheckout, SS_GetProductPaymentDetails } from "../../stripe"
import { useDefaultRoute } from "../../hooks/useDefaultRoute"
import { useNavigate } from "react-router-dom"
import FullScreenModal from "./FullScreenModal"
import { Logo } from "../logo"
import { extractEmojiAndText } from "../../helpers/extractEmojiAndText"

const SubscriptionWindow: FC = () => {

  const defaultRoute = useDefaultRoute()
  const navigate = useNavigate()
  const { data } = useGetSeasonTicketsQuery()
  const [subscriptions, setSubscriptions] = useState<SubscriptionTypeEntity[]>([])
  const [selectedPlan, setSelectedPlan] = useState<ComponentDataEntry>()
  const [paymentId, setPaymentId] = useState<{ payment?: string, id?: string }>()
  const [isYearly, setIsYearly] = useState<boolean>(true)
  const { setIsOpenSubscriptionModal, user, setUser, isOpenSubscriptionModal } = useContext(Context)

  useEffect(() => {
    const activeSubs = data?.subscriptionTypes?.data.filter((item) => item.attributes?.active) ?? []
    const sortedSubs = activeSubs.filter(item => {
      const needsKey = item?.attributes?.subscriptionItem?.key || ''
      const monthlySubs = needsKey === "monthly" || needsKey === "free"
      const yearlySubs = needsKey === "yearly" || needsKey === "free"
      return isYearly ? yearlySubs : monthlySubs
    })
    setSubscriptions(sortedSubs)
  }, [data, isYearly])

  useEffect(() => {
    if (subscriptions?.length && user?.subscription?.type === 'free') {
      const recommends = subscriptions.filter(item => item.attributes?.recommended)
      selectCard(recommends[0])      
    }
    if (localStorage.getItem("sessionId")) {
      const result = SS_GetProductPaymentDetails(localStorage.getItem("sessionId") || '')
      const subscription = {
        type: user?.subscription?.type,
        key: user?.subscription?.key,
        startDate: user?.subscription?.startDate,
        updatedDate: user?.subscription?.updatedDate,
        finishDate: user?.subscription?.finishDate
      }
      if (!result) {
        user?.subscription?.type === "free"
          ? clearSubscription(user?.subscription?.prompts)
          : clearSubscription(user?.subscription?.prompts, subscription)
      }
    }
  }, [subscriptions])

  const subscribeHandler = (item: SubscriptionTypeEntity) => {
    const sessionId = SS_ProductCheckout(item?.attributes?.paymentId!, user?.email!)
    localStorage.setItem("paymentId", item?.id!)
    localStorage.setItem("sessionId", sessionId)    
  }

  const selectCard = (item: SubscriptionTypeEntity) => {
    setSelectedPlan(item?.attributes?.subscriptionItem! as ComponentDataEntry)
    setPaymentId({ payment: item?.attributes?.paymentId || '', id: item?.id!})
  }
  const requestUnsubscribe = `mailto:feedback@partnerai.one?subject=Unsubscribe%20Request&body=Hello%2C%20PartnerAI%21%0D%0A%0D%0AI%20decided%20to%20unsubscribe%20my%20account%20from%20${user?.subscription?.type}%20${user?.subscription?.key}%20subscription.%20%0D%0AMy%20username%3A%20${user?.username}%2C%20my%20email%3A%20${user?.email}.`
  return (
    <FullScreenModal
      className={"subscription-page"}
      open={true}
      footer={[]}
      onCancel={() => navigate(-1)}
    >
      <Row className={`subscription`}>
        <Row className={'head'} align={'middle'}>
          <div className={'logo-block'}>
            <Logo/>
          </div>
          <h2 className={'title'}>Manage your subscription</h2>
          <p className={'description'}>
            Subscribe now and unlock the results that AI Partner has prepared for you
          </p>
          <Row justify={'center'} align={'middle'}>
            <div className={'plan-toggle'} onClick={() => setIsYearly(prevState => !prevState)}>
              <Col span={12} className={`toggle-active ${!isYearly ? 'monthly-active' : 'yearly-active'}`} />
              <Col span={12} className={`plan-toggle-item ${!isYearly ? 'monthly-active' : ''}`}>
                <span className={'plan-item'}>Monthly</span>
              </Col>
              <Col span={12} className={`plan-toggle-item ${isYearly ? 'yearly-active' : ''}`}>
                <span className={'plan-item'}>Yearly (save 16%)</span>
              </Col>
            </div>
          </Row>
        </Row>

        {/*<Row className={'yearly-toggle'} justify={"center"} align={'middle'}>*/}
        {/*  <div className={`toggle ${isYearly ? 'active' : ''}`} onClick={() => setIsYearly(prevState => !prevState)}>*/}
        {/*    <div className={'circle'} />*/}
        {/*  </div>*/}
        {/*  <p>*/}
        {/*    Yearly subscription*/}
        {/*    <span className={isYearly ? 'yearly' : ''}> (Get more done*/}
        {/*      <span className={isYearly ? 'green' : ''}> for less</span>)*/}
        {/*    </span>*/}
        {/*  </p>*/}
        {/*</Row>*/}
        <Row className={'cards'} justify={'center'}>
          {subscriptions?.map((item) => {
            const current = user?.subscription?.type.toLowerCase() === item?.attributes?.subscriptionItem?.value?.toLowerCase()
            const backgroundColor = item?.attributes?.subscriptionItem?.backgroundColor ?? '#F0F1F9'
            const extractedString = extractEmojiAndText(item?.attributes?.subscriptionItem?.displayValue!, '🔸')
            const description = item?.attributes?.description

            return <SubscriptionCard
                timePlan={item?.attributes?.subscriptionItem as Maybe<ComponentDataEntry>}
                price={item?.attributes?.price}
                advantages={item?.attributes?.advantages}
                isRecommended={!!(item?.attributes?.recommended && user?.subscription?.type === 'free')}
                isYearly={isYearly}
                current={current}
                onSubscribe={() => {
                  console.log(item)
                  subscribeHandler(item)
                }}
                color={backgroundColor!}
                icon={extractedString?.emoji!}
                title={extractedString?.text!}
                description={description!}
              />

          })}
        </Row>
        {/*<Row className={'subscribe'}>*/}
        {/*  <Col className={'button-box'}>*/}
        {/*    {(user?.subscription?.type !== selectedPlan?.value && selectedPlan?.value !== 'free' && selectedPlan) ?*/}
        {/*      <Button type={"primary"} className={"subscribe-btn SS_ProductCheckout"} onClick={subscribeHandler}*/}
        {/*             disabled={!paymentId?.payment || !user?.email}>*/}
        {/*      Subscribe now*/}
        {/*    </Button>*/}
        {/*    :*/}
        {/*    <Button type={"text"} className={"subscribe-btn downgrade SS_ProductCheckout"}*/}
        {/*            disabled={user?.subscription?.type === 'free' || !user?.email}>*/}
        {/*      <a href={requestUnsubscribe} target={"_blank"} rel={"noopener noreferrer"}>*/}
        {/*        Downgrade to free plan*/}
        {/*      </a>*/}
        {/*    </Button>}*/}
        {/*  </Col>*/}
        {/*  <p className={'hint'}>We'll not share any of your information. You can cancel anytime. For further details, please review our terms of use*/}
        {/*    <a href={"https://partnerai.one/terms-of-service"} target={"_blank"} rel={"noopener noreferrer"}> here</a>*/}
        {/*    .*/}
        {/*  </p>*/}
        {/*</Row>*/}
      </Row>
    </FullScreenModal>
  )
}

export default SubscriptionWindow
