import { Button, Col, Dropdown, Space } from "antd"
import React, {
  BaseSyntheticEvent,
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  SetStateAction, SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react"
import "./Login.less"
import type { MenuProps } from 'antd';
import { googleLogout } from '@react-oauth/google';
import { useMsal } from "@azure/msal-react"
import { loginRequest, msalConfig, msalInstance } from "../../../msalConfig"
import {
  useCreateUserFavouriteMenuMutation,
  useGetGlobalSettingQuery,
  useGetUsersMenuQuery, useGetUsersQuery,
  useLoginMutation,
  useRegisterMutation,
  useUpdateProfileMutation,
  useUpdateSubscriptionMutation,
} from "../../graphql"
import { Context, Subscription, User } from "../app"
import { useGoogleLogin } from "@react-oauth/google"
import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

const Login: FC<{children?: ReactNode, type: 'Microsoft' | 'Google' | 'redirect', action?: 'login' | 'signup', setText?: Dispatch<SetStateAction<string>>, setFail?: Dispatch<SetStateAction<boolean>>}> = ({ children, type, action, setText, setFail }) => {
  const { instance, accounts } = useMsal();

  const { data } = useGetGlobalSettingQuery();

  const { user, setUser, setIsLoadingPage } = useContext(Context)

  const [shortName, setShortName] = useState<string>('')

  const navigate = useNavigate()

  const initialTokens = data?.globalSetting?.data?.attributes?.initialTokens

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const checkoutSessionId = params.get('sessionId');

    if(checkoutSessionId) {
      localStorage.setItem('sessionId', checkoutSessionId as string)
    }
  }, [])

  const [loginMutation] = useLoginMutation()
  const [registerMutation] = useRegisterMutation()
  const [ updateSubscription ] = useUpdateSubscriptionMutation()
  const [ updateProfile ] = useUpdateProfileMutation()

  const [ createUserFavMenu ] = useCreateUserFavouriteMenuMutation()

  useEffect(() => {
    setIsLoadingPage(false)
    if (user?.username) {
      setShortName(getShortUsername())
    }
  }, [user])

  const googleCall = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`
        }
      })
      const userInfo = await res.json()      
      const name = userInfo.name ?? userInfo.email
      const email = userInfo.email
      action === "signup" ? register(name, email) : loginRegister(name, email) 
    },
  })
  
  const loginHandler = async () => {
    if (type === "redirect") {
      navigate('/signin')
      return
    }

    localStorage.setItem('provider', JSON.stringify(type))
    if (type === "Microsoft") {
      await msalInstance.handleRedirectPromise();

      const result = await instance.loginPopup(loginRequest)

      const name = result.account?.name!
      const email = result.account?.username!

      action === "signup" ? register(name, email) : loginRegister(name, email)    
    } else if (type === "Google") {
      googleCall()
    }
  }

  const loginRegister = async (name: string, email: string) => {

    try {
      const userLoggedData = await loginMutation({
        variables: {
          input: {
            identifier: email, password: email
          },
        },
      })

      const personData = localStorage.getItem('profile')
      await updateProfile({
        variables: {
          id: userLoggedData?.data?.login?.user?.id!,
          input: {
            connectedBy: type,
          }
        }
      })
      if (personData) {

        const parsedPerson = JSON.parse(personData) as string[]
        await updateProfile({
          variables: {
            id: userLoggedData.data?.login?.user?.id!,
            input: {
              person: [{
                personName: parsedPerson[0],
                selectedValue: parsedPerson?.map((item) => ({ value: item, displayValue: item }))
              }]
            }
          }
        })
      }

      setUser({ ...userLoggedData.data?.login?.user, connectedBy: type } as User)
      localStorage.setItem("token", userLoggedData.data?.login?.jwt!)
      localStorage.setItem("user", JSON.stringify(userLoggedData.data?.login.user))
    } catch (e) {
      setFail && setFail(true)
      setText && setText("⚠️ We haven’t found your account.")
    }
  }
    
  const register = async (name: string, email: string) => {    
    try {
      const userRegisteredData = await registerMutation({
        variables: {
          input: {
            username: email, password: email, email: email
          },
        },
      })

      const userSubscription = await updateSubscription({
        variables: {
          id: userRegisteredData.data?.register.user.id!, input: {
            fullname: name,
            connectedBy: type,
            subscription: {
              prompts: initialTokens ?? 3, type: "free",
            },
          },
        },
      })

      await createUserFavMenu({variables:{input:{id: userRegisteredData?.data?.register?.user?.id || ''}}})

      const personData = localStorage.getItem('profile')

      if (personData) {

        const parsedPerson = JSON.parse(personData) as string[]
        await updateProfile({
          variables: {
            id: userRegisteredData.data?.register.user.id!,
            input: {
              person: [{
                personName: parsedPerson[0],
                selectedValue: parsedPerson?.map((item) => ({ value: item, displayValue: item }))
              }]
            }
          }
        })
      }

      setUser({
        ...userRegisteredData.data?.register.user!,
        // @ts-ignore
        subscription: { ...userSubscription.data?.updateUsersPermissionsUser.data?.attributes?.subscription },
        connectedBy: type
      })

      localStorage.setItem("token", userRegisteredData.data?.register?.jwt!)
      localStorage.setItem("user", JSON.stringify(userRegisteredData.data?.register.user))
    } catch (e) {
      console.log(e)
      setFail && setFail(true)
      setText && setText("⚠️ This account exists.")
    }  
  }

  const logoutHandler = () => {
    const authProvider = localStorage.getItem('provider')

    setUser(undefined)
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    if (authProvider) {
      if (JSON.parse(authProvider) === 'Microsoft') {
        const logoutRequest = {
          account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
          postLogoutRedirectUri: msalConfig.auth.redirectUri,
        }
        instance.logoutPopup(logoutRequest)

        localStorage.removeItem('provider')
      } else if (JSON.parse(authProvider) === 'Google') {
        googleLogout();

        localStorage.removeItem('provider')
      }
    }
    navigate('/signin')
    location.reload()
  }

  const getShortUsername = () => {
    const splitedName = user?.username.split(" ")
    const firstLetters = splitedName?.map((item) => item.charAt(0)).join('');
    return firstLetters ?? ''
  }

  const itemsDropdown = [(user?.subscription?.type === 'free' && {
    key: 'go premium', label: <Button type={"text"} className={"authed-item premium"} onClick={() => navigate("/profile")}>Go Premium</Button>,
  }) ,{
    key: 1, label: <Button type={"text"} className={"authed-item"} onClick={() => navigate("/profile")}>My Profile</Button>,
  }, {
    key: 2, label: <Button type={"text"} className={"authed-item"} onClick={() => navigate("/history")}>My Prompts History</Button>,
  }, {
    key: 3, label: <Button type={"text"} className={"authed-item"} onClick={logoutHandler}>Log Out</Button>,
  }]

  const onDropdownSelect: MenuProps['onClick'] = (e) => {
    switch (e?.key) {
      case '1': navigate("/profile"); break;
      case '2': navigate("/history"); break;
      case '3': logoutHandler(); break;
    }
  }

  return (
      <Col>{!user
        ? <Button type={"text"} block className={"login"} onClick={loginHandler}>{!children ? "LOG IN →" : children}</Button>
        : <Dropdown
          menu={{items: itemsDropdown, onClick: onDropdownSelect, className: 'user-dropdown'}}
          placement={'bottomRight'}
        >
          <Button className={"authed"}>
          {shortName}
        </Button>
        </Dropdown>}
      </Col>
  )
}

export { Login }
