import './FullScreenModal.less'
import { Modal, type ModalProps } from "antd"
import React, { FC } from "react"
import { ReactComponent as CloseSVG } from '../../media/close-icon.svg'

const FullScreenModal:FC<ModalProps> = (props) => {

  return <Modal
    {...props}
    open={props?.open}
    className={`full-screen-modal ${!!props?.className ? props.className : ''}`}
    closeIcon={<CloseSVG/>}
  >
    {props.children}
  </Modal>
}

export default FullScreenModal