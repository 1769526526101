import React, { FC } from "react"
import "./LockScreen.less"
import { Typography } from "antd"


const LockScreen: FC = () => {

  return (
      <div className={'lock-screen'}>
        <h1>We currently support only the desktop version</h1>
      </div>
  )
}

export { LockScreen }
