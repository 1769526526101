import React, { FC, useContext } from "react"
import "./ClosedAccountScreen.less"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"
import { LogoutOutlined } from "@ant-design/icons"
import { Context } from "../app"

const ClosedAccountScreen: FC = () => {

  const { setUser } = useContext(Context)

  const navigate = useNavigate()

  const logout = () => {
    setUser(undefined)
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    navigate('/signin')
    location.reload()
  }

  return (
      <div className={'closed-screen'}>
        <h1>Your account is closed. Reach out to <a className={'support-link'} href={'mailto:support@partnerai.one'}>support@partnerai.one</a></h1>
        <Button type={'link'} onClick={logout}>Back to login <LogoutOutlined /> </Button>
      </div>
  )
}

export { ClosedAccountScreen }
