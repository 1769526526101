import emojiRegex from 'emoji-regex'

interface EmojiAndText {
  emoji: string | null;
  text: string;
}

export function extractEmojiAndText(inputString: string, defaultEmoji: string): EmojiAndText {
  const matches = inputString.match(emojiRegex());
  const emoji = matches ? inputString.split(' ')[0] : null;
  const text = emoji ? inputString.slice(emoji.length) : inputString;

  return {
    emoji: emoji ? emoji : defaultEmoji,
    text
  }
}