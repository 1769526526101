import { PublicClientApplication } from "@azure/msal-browser"
import { Configuration, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.WEBSITE_MS_CLIENT_ID,
    authority: import.meta.env.WEBSITE_MS_TENANT,
    redirectUri: '/',
    postLogoutRedirectUri: "/"
  },
  system: {
    allowNativeBroker: true,
    allowRedirectInIframe: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const loginRequest: RedirectRequest = {
  scopes: ["User.Read"]
};

export const msalInstance = new PublicClientApplication(msalConfig);